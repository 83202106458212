@import "variables";
@import "bootstrap/bootstrap";

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 140px; /* Margin bottom by footer height */
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 140px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  min-width: 500px;
}

.btn-discord {
  color: white;
  background-color: #5865f2;
  font-weight: 700;
  font-size: 11pt;
  line-height: 13pt;

  &:hover {
    background-color: #8ea2e1;
  color: white;
  }
}


h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6{
  font-weight: bold;
}

.page-content h1 {
  text-align: center;
  margin-bottom: 1em;
}
